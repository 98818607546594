.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-text {
  color: #fff !important;
}
.jumbotron {
  background-color: #007bff;
  background-image: linear-gradient(45deg, #007bff, white);
  color: #fff;
}
.requestapt {
  margin-top: 120px;
  margin-bottom: 400px;
}
.requestapt .jumbotron.jumbotron-fluid {
  background-color: #d4edda !important;
  background-image: none !important;
  color: #155724;
}
.bg-primary {
  background-image: linear-gradient(45deg, black, transparent);
}
.footer-bg {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #007bff !important;
  color: white;
  background-image: linear-gradient(45deg, black, transparent);
  text-align: center;
  padding: 30px;
}
.footer-bg .contact {
  margin-right: 5px;
}
.footer-bg .location,
.footer-bg .copyright,
.footer-bg .developer {
  margin-right: 5px;
  margin-left: 5px;
}
.footer-bg .developer a:active,
.footer-bg .developer a:visited,
.footer-bg .developer a:hover {
  color: #fff !important;
}
.healthprofessionals {
  width: 20rem;
  margin: 10px;
  margin-bottom: 30px;
}
.healthprofessionals .card-footer {
  text-align: center;
  background-color: white !important;
}
.healthprofessionals .list-group .list-group-item {
  border: none;
}
.healthprofessionals .card-subtitle {
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.cardinfo {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
}
.listimage,
.mailto,
.download,
.listservice,
.inscomp {
  margin-bottom: 40px;
}
.accordionpg {
  margin-bottom: 350px;
}
.inscomp .card:first-child {
  border: none;
}
.accordionpg .card {
  margin-bottom: 10px;
  border: 1px solid #007bff !important;
  border-bottom: 1px solid #007bff !important;
  background-color: #007bff;
  background-image: linear-gradient(45deg, black, transparent);
}
.accordionpg .card:nth-child(even) {
  border-bottom: 1px solid #007bff !important;
}
.accordionpg .card:nth-child(odd) {
  border-bottom: 1px solid #007bff !important;
}
.accordionpg .card .card-header .btn:focus {
  box-shadow: none;
}
.accordionpg .card .card-header .btn-link {
  color: #fff !important;
}

.listservice .card-columns .card,
.inscomp .card-columns .card {
  border: 1px solid black !important;
}
.listservice .card:first-child,
.inscomp .card:first-child {
  margin-bottom: 15px;
}
.inscomp .card,
.locationdesc .card {
  border: none !important;
}
.locationdesc .card-header {
  font-size: 30px;
  font-weight: 400;
  border: none;
  background: none;
}
.aidclocations .card {
  border: none !important;
}
.locationdesc:last-child {
  margin-bottom: 40px;
}
.listservice .card-columns .card .list-group .list-group-item,
.inscomp .card-columns .card .list-group .list-group-item {
  border-bottom: 1px solid black !important;
}
.listservice .card-columns .card .list-group .list-group-item:last-child,
.inscomp .card-columns .card .list-group .list-group-item:last-child {
  border-bottom: none !important;
}
.listimage .card,
.mailto .card,
.download .card,
.listimage .card .list-group .list-group-item {
  border: none !important;
}
.download .card-text a {
  margin-top: 10px;
}
.feedback .card {
  border: 5px solid #007bff !important;
  margin-bottom: 30px;
}
.feedback .card:first-child {
  margin-bottom: 20px;
}
.feedback {
  margin-bottom: 60px;
}
.cred {
  margin-bottom: 15px;
}
.cred .card {
  border: none !important;
}
@media only screen and (max-width: 600px) {
  .aidc .card-text:last-child {
    margin-bottom: 15px;
  }
  .accordionpg {
    margin-bottom: 60px;
  }
  .listservice,
  .inscomp {
    margin-bottom: 60px;
  }
  .locationdesc:last-child {
    margin-bottom: 60px;
  }
  .aidclocations .card iframe {
    width: 385px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) {
  .aidclocations .card iframe {
    width: 750px !important;
  }
}
